if( 'function' === typeof importScripts) {
  importScripts('https://www.gstatic.com/firebasejs/9.0.0/firebase-app-compat.js');
  importScripts('https://www.gstatic.com/firebasejs/9.0.0/firebase-messaging-compat.js');
  
  const firebaseConfig = {
    apiKey: "AIzaSyDkZ6AMDcS2tinNpKhV-56NLwM-mgwCW4o",
    authDomain: "purposeful-people.firebaseapp.com",
    databaseURL: "https://purposeful-people-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "purposeful-people",
    storageBucket: "purposeful-people.appspot.com",
    messagingSenderId: "137291546455",
    appId: "1:137291546455:web:4dd26afaff6950057f4812",
    measurementId: "G-4MRRTR8QE2"
  }
  
  firebase.initializeApp(firebaseConfig);
  
  const messaging = firebase.messaging();
  
  messaging.onBackgroundMessage(function(payload) {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
    }
  
    self.registration.showNotification(notificationTitle,
      notificationOptions);
  })
}
